/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): common-component.js
 * --------------------------------------------------------------------------
 */

import {
  typeCheckConfig
} from '../bootstrap/js/src/util/index'
import Data from '../bootstrap/js/src/dom/data'
import BaseComponent from '../bootstrap/js/src/base-component'
import Manipulator from '../bootstrap/js/src/dom/manipulator'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'missing'
const VERSION = '2.0.0-alpha1'

class CommonComponent extends BaseComponent {
  constructor(element, config) {
    super(element)
    this._config = this._getConfig(config)
  }

  // getters
  static get Name() {
    return NAME
  }

  // private
  _getConfig(config) {
    config = {
      ...this.constructor.Default,
      ...Manipulator.getDataAttributes(this._element),
      ...config
    }
    for (const property in config) {
      if (property.indexOf('_') > 0) {
        const splittedProperty = property.split('_')
        this._setPropertyRecusive(config, splittedProperty, config[property])
        delete config[property]
      }
    }

    typeCheckConfig(this.constructor.Name, config, this.constructor.DefaultType)
    return config
  }

  _getPropertyRecusive(object, keys) {
    if (keys.length > 1) {
      return this._getPropertyRecusive(object[keys.shift()], keys)
    }

    return object[keys]
  }

  _setPropertyRecusive(object, keys, value) {
    if (keys.length > 1) {
      this._setPropertyRecusive(object[keys.shift()], keys, value)
    } else {
      object[keys] = value
    }
  }

  /* Static */
  static initializeInterface(element, config) {
    let data = Data.get(element, this.constructor.DATA_KEY)
    const _config = typeof config === 'object' ? config : null

    if (!data) {
      data = new this(element, _config)
    }

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static _jQueryInterface(config) {
    return this.each(function () {
      this.constructor.initializeInterface(this, config)
    })
  }

  static get VERSION() {
    return VERSION
  }
}

export default CommonComponent
