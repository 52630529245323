/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): navbar-scroll-transition.js
 * --------------------------------------------------------------------------
 */

import {
  getjQuery,
  onDOMContentLoaded
} from '../../bootstrap/js/src/util/index'
import Data from '../../bootstrap/js/src/dom/data'
import EventHandler from '../../bootstrap/js/src/dom/event-handler'
import SelectorEngine from '../../bootstrap/js/src/dom/selector-engine'
import CommonComponent from '../common-component'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'navbar-scroll-transition'
const DATA_KEY = 'onma.navbar-scroll-transition'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Default = {
  scrollPosition: 30,
  offset: 0,
  offsetMd: 0,
  offsetLg: 0
}

const DefaultType = {
  scrollPosition: 'number',
  offset: 'number',
  offsetMd: 'number',
  offsetLg: 'number'
}

const Selector = {
  NAVBAR_SCROLL_TRANSITION: '[data-bs-plugin="navbar-scroll-transition"]'
}

const Event = {
  LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`,
  SCROLL: `scroll${EVENT_KEY}${DATA_API_KEY}`,
  RESIZE: `resize${EVENT_KEY}${DATA_API_KEY}`
}

/*
const ClassName = {
}
*/

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class NavbarScrollTransition extends CommonComponent {
  constructor(element, config) {
    super(element, config)

    this._lastPageYOffset = 0

    this._isTablet = window.matchMedia('(min-width: 768px)').matches
    this._isDesktop = window.matchMedia('(min-width: 992px)').matches

    EventHandler.on(window, Event.SCROLL, () => this._scrollY())
    EventHandler.on(window, Event.RESIZE, () => {
      this._isTablet = window.matchMedia('(min-width: 768px)').matches
      this._isDesktop = window.matchMedia('(min-width: 992px)').matches
      this._setElementOffsetTop()
      this._scrollY()
    })

    this._setElementOffsetTop()
    this._scrollY()
  }

  // getters
  static get Name() {
    return NAME
  }

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  // public

  // private
  _setElementOffsetTop() {
    if (this._isDesktop && this._config.offsetLg > 0) {
      this._element.style.top = `${this._config.offsetLg}px`
    } else if (this._isTablet && this._config.offsetMd > 0) {
      this._element.style.top = `${this._config.offsetMd}px`
    } else if (this._config.offset > 0) {
      this._element.style.top = `${this._config.offset}px`
    }
  }

  _scrollY() {
    let { offset } = this._config
    const { scrollY, pageYOffset } = window

    if (pageYOffset <= this._lastPageYOffset) {
      this._element.classList.remove('scrolled-down')
      this._element.classList.add('scrolled-up')
    } else {
      this._element.classList.remove('scrolled-up')
      this._element.classList.add('scrolled-down')
    }

    this._lastPageYOffset = pageYOffset

    if (this._isDesktop) {
      offset = this._config.offsetLg
    } else if (this._isTablet) {
      offset = this._config.offsetMd
    }

    if (scrollY > this._config.scrollPosition + offset && !this._element.classList.contains('navbar-scrolled')) {
      this._element.classList.add('navbar-scrolled')
    } else if (scrollY <= this._config.scrollPosition + offset && this._element.classList.contains('navbar-scrolled')) {
      this._element.classList.remove('navbar-scrolled')
    }

    if (!this._element.classList.contains('navbar-scrolled-transition')) {
      this._element.classList.add('navbar-scrolled-transition')
    }

    if (scrollY <= offset) {
      const diff = offset - scrollY
      this._element.style.top = `${diff}px`
    } else {
      this._element.style.top = 0
    }
  }

  // static
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */
EventHandler.on(window, Event.LOAD_DATA_API, event => {
  event.preventDefault()
  const navbarScrollTransitions = SelectorEngine.find(Selector.NAVBAR_SCROLL_TRANSITION)
  for (let i = 0, len = navbarScrollTransitions.length; i < len; i++) {
    NavbarScrollTransition.initializeInterface(navbarScrollTransitions[i], Data.get(navbarScrollTransitions[i], DATA_KEY))
  }
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .Carousel to jQuery only if jQuery is present
 */
onDOMContentLoaded(() => {
  const $ = getjQuery()
  /* istanbul ignore if */
  if ($) {
    const JQUERY_NO_CONFLICT = $.fn[NAME]
    $.fn[NAME] = NavbarScrollTransition.jQueryInterface
    $.fn[NAME].Constructor = NavbarScrollTransition
    $.fn[NAME].noConflict = () => {
      $.fn[NAME] = JQUERY_NO_CONFLICT
      return NavbarScrollTransition.jQueryInterface
    }
  }
})

export default NavbarScrollTransition
