/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): scroll.js
 * --------------------------------------------------------------------------
 */

import {
  getjQuery,
  onDOMContentLoaded
} from '../../bootstrap/js/src/util/index'
import {
  offset
} from './util/index'
import Data from '../../bootstrap/js/src/dom/data'
import EventHandler from '../../bootstrap/js/src/dom/event-handler'
import SelectorEngine from '../../bootstrap/js/src/dom/selector-engine'
import CommonComponent from '../common-component'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'scroll'
const DATA_KEY = 'onma.scroll'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Default = {
  // duration : 400,
  offset: 0
  // easing   : 'swing'
}

const DefaultType = {
  // duration : 'number',
  offset: 'number'
  // easing   : 'string'
}

const Selector = {
  SCROLL: '[data-bs-navigation="scroll"]',
  ANCHOR_LINK: 'a[href^="#"]'
}

const Event = {
  LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`,
  CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`
}

/*
const ClassName = {
}
*/

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Scroll extends CommonComponent {
  constructor(element, config) {
    super(element, config)
    SelectorEngine.find(Selector.ANCHOR_LINK, this.element).forEach(
      link => EventHandler.on(link, Event.CLICK_DATA_API, event => {
        const href = event.target.getAttribute('href')
        const target = SelectorEngine.findOne(href)
        if (target === null) {
          return
        }

        const targetOffset = offset(this._element)
        target.scrollIntoView({
          left: 0,
          top: targetOffset.top - this._config.offset,
          behavior: 'smooth'
        })
        /*
        $('html, body').animate({
          scrollTop: $target.offset().top - this._config.offset
        }, this._config.duration, this._config.easing)
        */
      })
    )
  }

  // getters
  static get Name() {
    return NAME
  }

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  // public

  // private

  // static
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */
EventHandler.on(window, Event.LOAD_DATA_API, event => {
  event.preventDefault()
  const scrolls = SelectorEngine.find(Selector.SCROLL)
  for (let i = 0, len = scrolls.length; i < len; i++) {
    Scroll.initializeInterface(scrolls[i], Data.get(scrolls[i], DATA_KEY))
  }
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .Carousel to jQuery only if jQuery is present
 */
onDOMContentLoaded(() => {
  const $ = getjQuery()
  /* istanbul ignore if */
  if ($) {
    const JQUERY_NO_CONFLICT = $.fn[NAME]
    $.fn[NAME] = Scroll.jQueryInterface
    $.fn[NAME].Constructor = Scroll
    $.fn[NAME].noConflict = () => {
      $.fn[NAME] = JQUERY_NO_CONFLICT
      return Scroll.jQueryInterface
    }
  }
})

export default Scroll
